<template>
  <div class="datasheet">
    <div class="ratio" :style="style"></div>
    <iframe ref="pdf" :name="iframeId" :id="iframeId" :src="url" width="100%" height="100%" v-if="url && !loading"/>
    <div class="d-flex flex-row position-absolute top-50 start-50 translate-middle" v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="pdf-toolbar">
      <button class="btn btn-primary" @click="printPdf">
        <i class="fas fa-print me-1"></i> Drucken
        <span class="visually-hidden">Drucken</span>
      </button>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  const pdfjsLib = require("pdfjs-dist");

  export default {
    name: 'datasheet-pdf',
    props: {
      url: {
        required: true,
        type: String,
      },
      width: {
        type: Number,
        default: 0
      },
      height: {
        type: Number,
        default: 0
      }
    },
    data: function () {
      return {
        index: -1,
        currentPage: null,
        pdf: null,
        loading: false,
        iframeId: 'pdf_iframe_' + window.crypto.randomUUID()
      };
    },
    methods: {
      printPdf(){
        window.frames[this.iframeId].print();
      },
      loadPdf(){
        this.loading = true;
        this.index = -1
        pdfjsLib.getDocument(this.url).promise.then((pdf)=>{
          this.pdf = pdf
          if(this.numberOfPages === 1){
            this.index = 1
          }
          this.loading = false;
        })
      }
    },
    computed: {
      size(){
        if(!this.currentPage){return {width: 1, height: 1}}
        const [x, y, w, h] = this.currentPage.view;
        const width = w - x;
        const height = h - y;
        const rotate = this.currentPage.rotate;
        return (rotate === 90 || rotate === 270) ? {width: height, height: width } : {width:width, height:height};
      },
      style(){
          const styles = {};
          if(this.size){
            styles["paddingBottom"] = `calc(100% / calc(${this.size.width} / ${this.size.height}))`
          }
          return styles;
      },
      numberOfPages(){
        if(!this.pdf){
          return 0
        }
        return this.pdf.numPages
      }
    },
    mounted(){
      // Setting worker path to worker bundle.
      pdfjsLib.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.js', import.meta.url);
      this.loadPdf();
    },
    watch:{
      index(newIndex){
        if(newIndex === -1){ return }
        this.pdf.getPage(newIndex).then((page)=>{
          this.currentPage = page;
        });
      },
      url(newUrl){
        if(!newUrl){ return }
        this.loadPdf();
      }
    }
  };
</script>
