<template>
  <div class="iconPicker">
    <vfa-picker v-model="selected">
      <template v-slot:activator="{ on }">
        <div class="btn-group">
          <button type="button" class="btn btn-primary iconpicker-component">
            <i :class="'fa ' + selected"></i>
          </button>
          <button
            type="button"
            class="iconPickerWidget btn btn-primary dropdown-toggle"
            @click="on"
            :data-selected="selected"
            data-toggle="dropdown"
          >
            <span class="caret"></span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu"></div>
        </div>
        <input type="hidden" :name="name" :value="selected" />
      </template>
    </vfa-picker>
  </div>
</template>
<script>
import Vue from "vue";
import VueFontAwesomePicker from "../components/vfa-picker";
Vue.use(VueFontAwesomePicker);

export default {
  name: "icon-picker-field",
  props: ["name", "value"],
  data: function () {
    return {
      selected: this.value,
    };
  },
};
</script>
