import Vue from 'vue'
import Cleave from 'cleave.js';

Vue.directive('cleave', {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {})
    el.addEventListener('input', (evt) => {
      console.log(evt.target.cleave)
    })
  },
})
