import Vue from 'vue';

Vue.directive('input-toggle', {
  // When the bound element is inserted into the DOM...
  inserted: function (el, options) {
    let inputs = document.querySelectorAll(options.value + ' input');
    if (el.checked) {
      inputs.forEach((el) => {
        el.disabled = false;
      });
    } else {
      inputs.forEach((el) => {
        el.disabled = true;
      });
    }

    el.addEventListener('change', (event) => {
      let inputs = document.querySelectorAll(options.value + ' input');
      if (event.currentTarget.checked) {
        inputs.forEach((el) => {
          el.disabled = false;
        });
      } else {
        inputs.forEach((el) => {
          el.disabled = true;
        });
      }
    });
  },
});
