<template>
  <div id="app">
    <tree
      :data="data"
      :options="options"
      ref="tree"
      @node:dragging:finish="updateHierarchy"
    >
      <div slot-scope="{ node }" class="node-container" @click="goToUrl(node)">
        <div class="node-text" :title="node.text">
          <i class="fal me-2" :class="{'fa-folder-open': node.expanded(), 'fa-folder': !node.expanded()}"></i>
          <span class="text">{{ node.text }}</span>
          <small v-if="node.data.description" class="badge badge-info">{{ node.data.description }}</small>
        </div>
        <div class="ms-3 node-controls" v-if="api">
          <a href="#" @click.stop="editNode(node)">Ändern</a>
          <a href="#" @click.stop="removeNode(node)">Löschen</a>
          <a href="#" @click.stop="addChildNode(node)">Add child</a>
        </div>
      </div>
    </tree>
  </div>
</template>

<script>
export default {
  name: 'tree-browser',
  props: ['tree', 'api', 'draggable'],
  data: function () {
    return {
      options: {
        'multiple': false,
        'dnd': this.draggable,
        'keyboardNavigation': false,
        // 'parentSelect': true,
        'propertyNames': {
          'text': 'title',
          'description': 'description',
          'children': '__children',
        },
      },
    };
  },
  computed: {
    'data': function () {
      return JSON.parse(this.tree);
    },
  },
  mounted() {
    this.$refs.tree.$on('node:editing:stop', (node, previousText) => {
      if (node.text == previousText) {
        return;
      }

      this.$http.put(this.api + '/' + node.id, {
        title: node.text,
      }).then(response => {
        this.busy = false;
      }, response => {
        this.busy = false;
      });
    });
  },
  methods: {
    editNode(node, e) {
      if (node.data.actions.edit) {
        document.location = node.data.actions.edit.uri;
      } else {
        node.startEditing();
      }
    },

    goToUrl(node) {
      if (node.data.targetUri) {
        window.location.href = node.data.targetUri;
      }
    },

    removeNode(node) {
      if (confirm('Are you sure?')) {
        this.$http.delete(this.api + '/' + node.id).then(response => {
          this.busy = false;
        }, response => {
          this.busy = false;
        });
        node.remove();
      }
    },

    addChildNode(node) {
      if (node.enabled()) {
        this.$http.post(this.api, {
          title: 'neu',
          parent: this.api + '/' + node.id,
        }).then(response => {
          response.body.text = response.body.title;
          node.append(response.body);
        }, response => {
        });
      }
    },
    updateHierarchy(node) {
      if (!this.api) {
        return;
      }
      let siblings = [];
      let parentId = 1;
      if (node.parent) {
        for (let i = 0; i < node.parent.children.length; i++) {
          siblings[i] = node.parent.children[i].id;
        }
        parentId = node.parent.id;
      } else {
        for (let i = 0; i < node.tree.model.length; i++) {
          siblings[i] = node.tree.model[i].id;
        }
      }

      this.$http.put(this.api + '/' + parentId + '/update-sorting', {
        children: siblings,
      }).then(response => {
      }, response => {
      });
    },
  },
};
</script>
