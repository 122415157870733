<template>
  <div class="favoriteToggle" @click="toggle">
    <i :class="{'fas': isActive, 'far': !isActive, 'fa-star':true}"></i>
  </div>
</template>

<script>
export default {
  name: 'favorite-toggle',
  props: ['active', 'updateUri'],
  data: function () {
    return {
      'isActive': this.active,
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
      this.$http.put(this.updateUri, {}).then(response => {
      }, response => {
      });
    },
  },
};
</script>
