import Vue from "vue";
import VueResource from "vue-resource";
import LiquorTree from "liquor-tree";
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin)

Vue.use(VueResource);
Vue.use(LiquorTree);
Vue.config.ignoredElements = ['trix-editor']

require
    .context("./components/", true, /\.vue$/)
    .keys()
    .forEach(function(elementPath) {
        let element = require("./components/" + elementPath.replace("./", ""))
            .default;
        if (element.name) Vue.component(element.name, element);
    });
require
    .context("./fields/", true, /\.vue$/)
    .keys()
    .forEach(function(elementPath) {
        let element = require("./fields/" + elementPath.replace("./", "")).default;
        if (element.name) Vue.component(element.name, element);
    });
require
    .context("./directives/", true, /\.js/)
    .keys()
    .forEach(function(elementPath) {
        require("./directives/" + elementPath.replace("./", "")).default;
    });

// PRESET WITH CAUTION
document.addEventListener('ea.collection.item-added', () => {
  window.document.dispatchEvent(new Event("DOMContentLoaded", {
    bubbles: true,
    cancelable: true
  }))
});


export default new Vue({
  mounted() {
    require('@EasyAdminBundle/js/field-text-editor.js');

    ["VueLoaded"].forEach( (eventName) => {
      window.document.dispatchEvent(new Event(eventName, {
        bubbles: true,
        cancelable: true
      }))
    })
  }
});
