<template>
  <div class='table-sorter' ref='container'>
    <slot></slot>
  </div>
</template>

<script>
import tableDragger from 'table-dragger';
import u from 'umbrellajs';

export default {
  name: 'drag-drop-table-sorter',
  props: ['updateUri', 'entityType', 'sortFieldName', 'sortOrder', 'sortGroup'],
  mounted: function() {
    if (this.$refs['container'].querySelectorAll('.table.datagrid .handle').length == 0) {
      return;
    }
    var el = this.$refs['container'].querySelector('.table.datagrid');
    var dragger = tableDragger(el, {
      mode: 'row',
      dragHandler: '.handle',
      onlyBody: true,
      animation: 300
    }).on('drop', (from, to, el, mode) => {
      let positions = [];
      u('.table-sorter .handle').each(function(handle){
        positions.push(u(handle).data('entity-id'));
      });
      this.$http.put(this.updateUri, {
        'group': this.sortGroup,
        'positions': positions
      }).then(response => {

        }, response => {

      });
    });
  }
};
</script>
