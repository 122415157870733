<template>
  <div class="translationTextarea">
    <textarea :name="name"
              v-model="internalValue"
              @input="updateTranslation($event.target.value)"
              type="text"
              class="form-control"></textarea>
    <div :class="getInputLoadingClass()"></div>
  </div>
</template>
<script>
export default {
  name: 'translation-textarea',
  props: ['name', 'value', 'uri'],
  data: function () {
    return {
      internalValue: this.value,
      debounceTimeout: 0,
      busy: false,
    };
  },
  methods: {
    updateTranslation(value) {
      clearTimeout(this.debounceTimout);
      this.debounceTimout = setTimeout(() => {
        this.busy = true;
        this.$http.put(this.uri, {
          translation: value,
        }).then(response => {
          console.log(response);
          this.busy = false;
        }, response => {
          this.busy = false;
        });
      }, 500);
    },
    getInputLoadingClass() {
      return {
        'loadingBar--inline': true,
        'loadingBar--loading': this.busy,
      };
    },
  },
};
</script>
