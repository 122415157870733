<template>
  <div>
    <div class="d-flex flex-row mb-3">
      <div class="btn-group">
        <input type="radio" class="btn-check" :value="null" v-model="year" id="all" autocomplete="off">
        <label class="btn btn-outline-primary" for="all">
          Die Letzten {{years.length}} Jahre
        </label>
        <template v-for="(cYear, index) in years">
          <input type="radio" class="btn-check" :value="cYear" v-model="year" :id="`year-${index}`" autocomplete="off">
          <label class="btn btn-outline-primary" :for="`year-${index}`">{{cYear}}</label>
        </template>
      </div>
      <hr class="flex-grow-1 mx-2">
    </div>
    <chart-bar ref="chartcmp" :chart-data="chartData" :chart-options="chartOptions"></chart-bar>

  </div>
</template>

<script lang="js">
import { Bar } from 'vue-chartjs/legacy'
export const COLORS = ["#7380cc",
  "#83b736",
  "#965fc8",
  "#50b957",
  "#d2519e",
  "#57c59e",
  "#cb4c3d",
  "#48adcf",
  "#c0ab36",
  "#c06680",
  "#547e2e",
  "#da8b48",
  "#3a865f",
  "#8a6b2e",
  "#a3af64"]
const randomHsl = () => `hsla(${Math.random() * 360}, 50%, 75%, .95)`
export default {
  components: {
    'chart-bar': Bar
  },
  name: 'customer-chart',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      year: null,
    }
  },
  computed: {
    colors () {
      const colors = {}
      Object.keys(this.data.revenue).forEach((year, index) => {
        colors[year] = COLORS[index]
      })
      return colors
    },
    years () {
        return Object.keys(this.data.revenue)
    },
    selectedYears () {
        return this.year ? [this.year] : this.years
    },
    revenueFromCompanies(){
      const revenue = {}
      this.selectedYears.forEach((year, index) => {
        const companiesFromThatYear = Object.keys(this.data.revenue[year])
        const revenueFromThatYear = [this.data.revenue[year]]
        companiesFromThatYear.forEach((company) => {
          const total = revenueFromThatYear.reduce((acc, revenues) => acc + revenues[company], 0)
          if(total > 0){
            if(  !revenue [company]){
              revenue [company] = 0
            }
            revenue[company] += total
          }
        })
      })
      return revenue
    },
    sortByRevenue(){
      return Object.keys(this.revenueFromCompanies).sort((a, b) => {
        return this.revenueFromCompanies[b] - this.revenueFromCompanies[a]
      })
    },
    dataSets(){
      return this.selectedYears.map((year) => {
        return {
          label: year,
          data: this.sortByRevenue.map( (company) => {
            return this.revenueFromCompanies[company]
          }),
          backgroundColor: this.colors[year]
        }
      })
    },
    chartData() {
      return { datasets: this.dataSets, labels: this.sortByRevenue }
    },
    chartOptions() {
      return {
        responsive: true,
        aspectRatio: 9/16,
        indexAxis: 'y',
        scaleShowValues: true,
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
                modifierKey: 'ctrl'
              },
              pinch: {
                enabled: true,
                modifierKey: 'ctrl'
              },
              mode: 'y',
            }
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(tooltipItem.parsed.x);
              }
            }
          },
          legend: {
            display: !this.year
          }
        },
        scales: {
          y: {
            stacked: true,
            ticks: {
              autoSkip: false,
              padding: 10,
              fontSize: 10
            }
          },
          x: {
            stacked: true,
            position: 'top',
            ticks: {
              callback: function (value, _index, _ticks) {
                return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(value)
              }
            }
          }
        }
      }
    }
  }
}
</script>











