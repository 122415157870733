<template>
  <div class="rangeField">
    <input  type="range"
            :id="id"
            v-model="internalValue"
            :min="min"
            :max="max"
            :step="step">
    {{internalValue}}
  </div>
</template>

<script>
export default {
  name: 'range-field',
  props: ['id', 'value', 'min', 'max', 'step', 'updateUri', 'property'],
  data: function () {
    return {
      'internalValue': this.value,
      'internalUpdateTimeout': null,
    };
  },
  watch:{
    internalValue: function(value) {
      if (this.internalUpdateTimeout) {
        clearTimeout(this.internalUpdateTimeout);
      }
      this.internalUpdateTimeout = setTimeout(this.update, 300);
    }
  },
  methods: {
    update() {
      if (this.internalUpdateTimeout) {
        clearTimeout(this.internalUpdateTimeout);
      }
      let data = {};
      data[this.property] = parseInt(this.internalValue);
      this.$http.put(this.updateUri, data).then(response => {
      }, response => {
      });
    }
  }
};
</script>
