<template>
  <div class="d-inline-block">
    <slot name="bell" :modalId="id" :showModal="showNotification" :hasNotfication="hasNewNotifications">
      <a class="notification-toggle" :class="{'notification-toggle--hasNew': hasNewNotifications}" @click="showNotification" data-bs-toggle="modal" :href="'#' + id" role="button">
        <i :class="{'fa-bell-shake animated': hasNewNotifications}" class="fa fa-bell" aria-hidden="true"></i>
      </a>
    </slot>
    <slot name="modal" :modalId="id" :hasNotifications="hasNotifications" :notifications="newsItems">
      <div class="modal fade" :id="id">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ headline }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="notification">
                <template v-if="hasNotifications">
                  <div class="newsItems" v-if="hasNotifications">
                    <div class="newsItem" v-for="newsItem in newsItems" :key="newsItem.id">
                      <div class="newsItem-content">
                        <div class="newsItem-date">{{newsItem.createdAt}}</div>
                        <h3 class="pageIntro__title">{{newsItem.title}}</h3>
                        <div class="newsItem-teaser" v-html="newsItem.content"></div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <slot name="noNotifications">
                    <div class="alert alert-info text-center" role="alert">
                      <h4 class="alert-heading">Sie sind auf dem Laufenden!</h4>
                      <p class="mb-0">Zurzeit gibt es keine aktuellen Benachrichtigungen.</p>
                    </div>
                  </slot>
                </template>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
  import axios from 'axios';
  import Vue from 'vue';
  import Cookies from 'js-cookie';

  export default {
    name: 'user-notifications',
    data: function () {
      return {
        busy: false,
        newsItems: { },
        hasNewNotifications: false,
        showNotifications: false,
        lastRead: null,
        id: 'modal-' + window.crypto.randomUUID()
      };
    },
    props: {
      headline: {
        type: String,
        default: 'Neuigkeiten'
      }
    },
    computed: {
      langauge(){
          const params = new URLSearchParams(document.location.search)
          if(params.has('locale')){
            return params.get('locale')
          }
          const root = document.querySelector('html');
          return root.getAttribute('lang');
      },
      notifications(){
        return Object.values(this.newsItems)
      },
      hasNotifications(){
        return this.notifications.length > 0
      }
    },
    methods: {
      showNotification() {
        this.showNotifications = true;
        if(this.hasNewNotifications){
          axios.post("/api/notifications/read", {}, {
            headers: {
              "Accept": "application/json",
              "Authorization": 'Bearer ' + Cookies.get('token')
            }
          }).then((response)=>{
            this.lastRead = parseInt(response.data.date);
            this.hasNewNotifications = false;
          })
        }
      },
      loadNews: function () {
        if (this.busy) {
          return;
        }
        this.busy = true;
        axios.get('/api/notifications/unread',{params: {
            locale: this.langauge
          },
          headers: {
            "Accept": "application/json",
            "Authorization": 'Bearer ' + Cookies.get('token')
          }})
          .then(response => {
            const data = response.data
            if(data.unread.length > 0 ){
              this.hasNewNotifications = true;
            }
            this.newsItems =  [].concat(data.unread, data.read)

          }).finally(() => {
          this.busy = false;
        });
      },
    },
    beforeMount: function () {
      this.loadNews();
      setInterval(this.loadNews, 30000);
    },
  };
</script>
