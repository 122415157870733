<template>
  <div class="position-relative w-100" ref="container">
    <div class="scroll-guard" ref="guard" style="margin-bottom: -1px; height: auto; min-height: 1px; display: flex;"></div>
    <datasheet-pdf ref="datasheet" v-if="datasheetReady" :url="datasheet"></datasheet-pdf>
    <div class="d-flex flex-row position-absolute top-0 start-50 translate-middle-x" style="height: 100px" v-if="loading">
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
let observer = null;
export default {
  name: 'datasheet',
  data: function () {
    return {
      'fields': {
        'turtyp': '',
        'kenncode-des-produktes': '',
        'flugel': '',
        'falz': '',
        'offnung': '',
        'schwelle': ''
      },
      files: [],
      basePath: "",
      loading: false,
      contentHeaderScrolls: window.innerWidth > 992,
      tmp: {},
      hasFetchedDataSheets: false
    };
  },
  computed: {
    filename(){
      return Object.values(this.fields).join('_').toLowerCase() + '.pdf';
    },
    datasheet() {
      if(!this.hasFetchedDataSheets) {
        return null;
      }
      if (this.files.indexOf(this.filename) > -1) {
        return `/${this.basePath}/` + this.filename + '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';
      }
    },
    datasheetReady(){
      return this.datasheet && !this.loading
    }
  },
  methods: {
      unObserve(){
        observer.unobserve(this.$refs.guard)
      },
      createObserver(){
        const rootOffset = document.querySelector(".content-header").scrollHeight;
        const element = this.$refs.datasheet.$el;
        observer = new IntersectionObserver((entries)=>{
          entries.forEach((entry)=> {
            if(!entry.isIntersecting){
              element.style.top = entry.rootBounds.top + 'px';
              element.style.position = "fixed";
              element.style.width = this.$refs.guard.getBoundingClientRect().width + 'px';
            } else {
              element.style.top = 0 + 'px';
              element.style.position = "relative";
              element.style.width = 100 + '%';
            }
          })
        }, {
          rootMargin: this.contentHeaderScrolls ?  (rootOffset * -1 ) +'px' : '0px',
        });
        observer.observe(this.$refs.guard)
      }
  },
  beforeMount(){
    this.loading = true;
    axios.get("/api/declaration/datasheet", {headers: { "Accept": "application/json" }}).then((resp)=>{
      this.files = resp.data.files
      this.basePath = resp.data.base
      this.loading = false;
      this.hasFetchedDataSheets = true;
    });
  },
  watch: {
    contentHeaderScrolls(){
      // regardless of a change, we have to react
      this.unObserve();
      this.createObserver();
    },
    datasheetReady(isReady){
      if(isReady){
        this.$nextTick(this.createObserver)
        window.addEventListener("resize", ()=> {
          this.contentHeaderScrolls = window.innerWidth > 992;
        });
      }
    }
  },
  mounted: function() {
    for(let fieldName in this.fields) {
      let field = document.querySelector('#Declaration_' + fieldName);
      field.addEventListener('change', (e) =>{
        this.fields[fieldName] = field.options[field.selectedIndex].getAttribute('data-name');
      }, false);
      if (field.selectedIndex != -1) {
        this.fields[fieldName] = field.options[field.selectedIndex].getAttribute('data-name');
      }
    }

  },
};
</script>
