import Vue from 'vue'
const store = new Map();
const mapping = new Map();
const dataSymbol = Symbol.for('identifier')


// Options for the observer (which mutations to observe)
const config = { attributes: true };


const applyClassMap = (observable, attribute = 'class') => {
  const attributeValue = observable.getAttribute(attribute)
  const elementsToIterate = store.has(observable.dataset[dataSymbol]) ? store.get(observable.dataset[dataSymbol]).elements : []
  elementsToIterate.forEach((el) => {
    if(!mapping.has(el.dataset[dataSymbol])) { return }
    const classMap = mapping.get(el.dataset[dataSymbol])
    for (const fromClass in classMap) {
      const toClass = classMap[fromClass]
      if(attributeValue.includes(fromClass)){
        if(!el.classList.contains(toClass)) {
          if(Array.isArray(toClass)){
            el.classList.add(...toClass)
          } else {
            el.classList.add(toClass)
          }
        }
      } else {
        if(Array.isArray(toClass)){
          el.classList.remove(...toClass)
        } else {
          el.classList.remove(toClass)
        }
      }
    }
  })
}

const callback = (mutationList, observer) => {
  for (const mutation of mutationList) {
    if(mutation.type === 'attributes'){
      applyClassMap(mutation.target, mutation.attributeName)
    }
  }
};


// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);


Vue.directive('mObserve', {
  bind: (el, binding) => {
    if(binding.arg === 'element'){
      const nodeToBeObserved = binding.value instanceof HTMLElement ? binding.value  : document.querySelector(binding.value)
      const id = nodeToBeObserved.dataset[dataSymbol] ? nodeToBeObserved.dataset[dataSymbol] :  window.crypto.randomUUID();
      if(!nodeToBeObserved.dataset[dataSymbol]){
        nodeToBeObserved.dataset[dataSymbol] = id;
        el.dataset[dataSymbol] = window.crypto.randomUUID();
        store.set(id, {
          observable: nodeToBeObserved,
          elements: [el]
        })
        observer.observe(nodeToBeObserved, config);
      } else {
        const records = store.get(id)
        records.elements.push(el)
        store.set(id, records)
      }
    }
    if(binding.arg === 'apply'){
      if(!el.dataset[dataSymbol]){
        return;
      }
      const record = mapping.get(el.dataset[dataSymbol])
      if(!record){
        mapping.set(el.dataset[dataSymbol], binding.value)
      }
    }
    if(mapping.size && store.size){
      Array.from(store.values()).forEach((record) => {
        applyClassMap(record.observable, 'class')
      })
    }
  },
  unbind: () => {
    // stop observing on this element
    observer.disconnect();
  }
})
