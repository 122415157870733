<template>
  <chart-line :chart-data="chartData" :chart-options="chartOptions"></chart-line>
</template>

<script lang="js">
import { Bar } from 'vue-chartjs/legacy'
import { COLORS } from './CustomerChart'
const date = (month = 1) => {
  const date = new Date();
  date.setHours(0,0,0,0)
  date.setFullYear(date.getFullYear(), month - 1, 1)
  return date
}

export default {
  components: {
    'chart-line': Bar
  },
  name: 'year-month-chart',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartOptions(){
        return {
          responsive: true,
          aspectRatio: 16/9,
          scales: {
            y:{
              ticks: {
                callback: function(value, _index, _ticks){
                  return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(value)
                }
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(context.parsed.y);
                }
              }
            }
          }
        }
    },
    chartData(){
      return Object.keys(this.data).reduce((acc, year,index)=> {
        acc.datasets.push({
          label: `Jahr ${year}`,
          data: Object.values(this.data[year]),
          backgroundColor: COLORS[index],
          tension: 0.1,
        })
        return acc
      }, {
        datasets: [],
        labels: this.months.map((val) => {
          return new Intl.DateTimeFormat('de-CH', { month: 'long' }).format(val)
        })
      })
    },
    months () {
      return [
        date(1),
        date(2),
        date(3),
        date(4),
        date(5),
        date(6),
        date(7),
        date(8),
        date(9),
        date(10),
        date(11),
        date(12)
      ]
    }
  },
};
</script>











