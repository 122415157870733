<template>
  <div>
    <div class="d-flex flex-row mb-3">
      <div class="btn-group">
        <input type="radio" class="btn-check" :value="null" v-model="year" id="all" autocomplete="off">
        <label class="btn btn-outline-primary" for="all">
          Die Letzten {{years.length}} Jahre
        </label>
        <template v-for="(cYear, index) in years">
          <input type="radio" class="btn-check" :value="cYear" v-model="year" :id="`year-${index}`" autocomplete="off">
          <label class="btn btn-outline-primary" :for="`year-${index}`">{{cYear}}</label>
        </template>
      </div>
      <hr class="flex-grow-1 mx-2">
    </div>
    <chart-bar :chart-data="chartData" :chart-options="chartOptions"></chart-bar>
  </div>
</template>

<script lang="js">
import { Bar } from 'vue-chartjs/legacy'
import { COLORS } from './CustomerChart'
export default {
  components: {
    'chart-bar': Bar
  },
  name: 'product-chart',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      year: null
    }
  },
  computed: {
    colors () {
      const colors = {}
      this.data.types.forEach((type, index) => {
        colors[type] = COLORS[index]
      })
      return colors
    },
    /**
     * Important to generate the Year Filters
     * @returns {string[]}
     */
    years () {
      return Object.keys(this.data.stats)
    },
    /**
     * 'collection' is a collection of product types. The Types are grouped by each product.
     * if a filter is selected we will receive the collection from that year
     * @returns {*[]|unknown[]}
     */
    collection () {
      return this.year ? [this.data.stats[this.year]] : Object.values(this.data.stats)
    },
    /**
     * Products and the amount of types of configuration. if the amount is 0 the product is removed.
     * @returns {{}}
     */
    products () {
      const products = {}
      this.data.products.forEach((product) => {
        if(!products[product]){
          products[product] = {}
        }
        this.data.types.forEach((type) => {
          const value = this.collection.reduce((acc, byYear) => {
            const types = byYear[product] ? byYear[product] : {}
            acc += types[type] ? types[type] : 0
            return acc
          }, 0)
          if(value){
            products[product][type] = value
          }
        })
        const count = Object.values(products[product]).length
        if(count === 0){
          delete products[product]
        }
      })
      return products
    },
    /**
     * Sorted by the total of types
     */
    sortedProducts(){
      return Object.keys(this.products).sort(($a, $b) => {
        const totalA = Object.values(this.products[$a]).reduce ((acc, val) => acc + val , 0 )
        const totalB = Object.values(this.products[$b]).reduce ((acc, val) => acc + val , 0 )
        return totalB - totalA
      })
    },
    /**
     * in this chart we use the "types" as dataset.
     * @returns {*[]}
     */
    dataSets(){
      const dataset = []
      this.data.types.forEach((type) => {
        const allProductTypes = Object.values(this.products);
        const exists = allProductTypes.some((types) => !!types[type] )
        if(!exists){ return }
        dataset.push({
          label: type,
          data: this.sortedProducts.map( (product) => {
            return this.products[product][type]
          }),
          backgroundColor: this.colors[type]
        })
      })
      return dataset
    },
    chartData() {
      return { datasets: this.dataSets, labels: this.sortedProducts }
    },
    chartOptions() {
      return {
        responsive: true,
        scales: {
          x: {
            position: 'top',
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
    }
  }
}
</script>











